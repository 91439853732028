import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service'; // Importe o serviço de autenticação aqui

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      return this.authService.isAdmin()
      .then((isAdmin: boolean) => {
        if (isAdmin) {
          return true;
        } else {
          return this.router.createUrlTree(['/dashboard']);
        }
      })
      .catch(error => {
        return false;
      });
  }
}
